import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

export const Link = props => {
  const { children, className, to, title, disabled, onClick = () => {} } = props;
  const isExternal = (to && to.indexOf('http') !== -1) || (to && to[0] === '#');
  const isSearchLink = to && to.includes('https://order.yordar.com.au/search');
  let searchLink;
  if (isSearchLink) {
    const { 6: suburbLink } = to.split('/');
    if (!suburbLink) {
      searchLink = '#no-suburb';
    }
  }

  const sameTab = (to && to.indexOf('yordar.com.au') !== -1) || (to && to[0] === '#');

  const disableClick = event => event.preventDefault();

  const handleLinkClick = e => {
    if (!e?.target?.href?.includes('no-suburb')) return;
    e.preventDefault();
    const searchbar = document.querySelector('.searchbar');
    const searchbarInput = searchbar.querySelector('input');
    const searchBarLink = searchbar.querySelector('button');
    if (searchbarInput.value) return searchBarLink.click();
    scrollIntoView(searchbar);
    const enterAddressTooltip = document.querySelector('.enter-address-tooltip');
    enterAddressTooltip.style.display = 'block';
  };

  if (isExternal) {
    return (
      <a
        href={searchLink ? to + searchLink : to}
        onClick={disabled ? disableClick : handleLinkClick}
        className={className || ''}
        title={title || null}
        target={sameTab ? '_self' : '_blank'}
        rel={sameTab ? '' : 'nofollow noopener noreferrer'}
      >
        {children}
      </a>
    );
  }

  const toStrippedSlash = to.substr(-1) === '/' ? to.substr(0, to.length - 1) : to;

  return (
    <GatsbyLink
      to={toStrippedSlash}
      className={className || ''}
      activeClassName="active"
      title={title || null}
      onClick={disabled ? disableClick : onClick}
    >
      {children}
    </GatsbyLink>
  );
};
