import React, { useState, useEffect } from 'react';

export const WithGoogleApi = WrappedComponent => {
  const WithGoogleApiComponent = props => {
    const [mounted, setMounted] = useState(false);

    const onLoad = () => {
      setMounted(true);
    };

    useEffect(() => {
      if (!window.google) {
        const script = document.createElement(`script`);
        script.type = `text/javascript`;
        script.src = `https://maps.google.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API_KEY}&libraries=places`;
        const headScript = document.getElementsByTagName(`script`)[0];
        headScript.parentNode.insertBefore(script, headScript);
        script.addEventListener(`load`, onLoad);
        return () => script.removeEventListener(`load`, onLoad);
      }
      onLoad();
    });

    if (!mounted) return null;
    return <WrappedComponent {...props} />;
  };
  return WithGoogleApiComponent;
};
