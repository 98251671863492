import React, { useState, useEffect } from 'react';
import OnVisible from 'react-on-visible';
import { Carousel, GetStartedForm, SupplierSignUp, Image, Newsletter, QuoteButton, SearchBar } from '../../components';
import './styles.scss';

const Hero = ({ data, location }) => {
  const { primary, items } = data;
  const { title, content, cta_ui: ctaUi, custom_class: customClass, background } = primary;

  const [textSlider, setTextSlider] = useState(null);

  useEffect(() => {
    if (background) {
      const header = document.querySelector('.header');
      const megaNav = document.querySelector('.header-navigation-parent-nav.open');
      if (header) header.style.backgroundColor = background;
      if (megaNav) megaNav.style.backgroundColor = background;
    }
  }, [background]);

  const handleOnSlideChanged = event => {
    const { item } = event;
    if (textSlider) textSlider.slideTo(item);
  };

  const setTextSliderRef = ref => {
    setTextSlider(ref);
  };

  const getCtaComponent = () => {
    if (ctaUi === 'Supplier Search Bar') return SearchBar;
    if (ctaUi === 'Newsletter Signup') return Newsletter;
    if (ctaUi === 'Supplier Signup') return SupplierSignUp;
    if (ctaUi === 'Home Delivery Search Bar') return SearchBar;
    if (ctaUi === 'Office Snacks Search Bar') return SearchBar;
    if (ctaUi === 'Quote Button Catering') return QuoteButton;
    if (ctaUi === 'Quote Button Snacks') return QuoteButton;
    if (ctaUi === 'Quote Button Event') return QuoteButton;
    return GetStartedForm;
  };

  const getCategoryGroup = () => {
    if (ctaUi === 'Office Snacks Search Bar') return 'office-snacks';
    return 'office-catering';
  };

  const CtaComponent = getCtaComponent();
  const categoryGroup = getCategoryGroup();

  const hasTextSlider = items && items.every(item => item.content.html);

  return (
    <OnVisible
      className={`hero ${background ? 'with-custom-bg' : ''} ${hasTextSlider ? 'with-text-slider' : ''} ${
        customClass && customClass.text ? customClass.text : ''
      }`}
      style={background ? { backgroundColor: background } : null}
    >
      <div className="wrapper">
        <div className="hero-left">
          <h1 className="hero-title">{title.text}</h1>
          {content && content.html && (
            <div className="hero-content" dangerouslySetInnerHTML={{ __html: content.html }} />
          )}
          <CtaComponent buttonText="Search" location={location} categoryGroup={categoryGroup} ctaUi={ctaUi} />
          {hasTextSlider && <HeroTextSlider items={items} setRef={setTextSliderRef} />}
        </div>
        {!background ? (
          <div className="hero-right">
            <HeroImageSlider items={items} onSlideChanged={handleOnSlideChanged} />
          </div>
        ) : null}
      </div>
      {background ? <Image className="hero-image-fill" image={items[0].photography} /> : null}
    </OnVisible>
  );
};

const HeroTextSlider = ({ items, setRef }) => {
  if (!items || items.length < 1) return null;
  // Hide text slider if no content entered
  const hasTextContent = items.every(item => item.content.html);
  if (!hasTextContent) return null;
  // Render slider
  return (
    <Carousel
      setRef={setRef}
      containerClassName="hero-text-slider"
      swipeDisabled
      fadeOutAnimation
      dotsDisabled
      buttonsDisabled
      disableSlider={items.length < 2}
    >
      {items.map(item => {
        const { content: itemContent, small_text: itemSmallText } = item;
        return (
          <div className="hero-text-slider-item">
            {itemContent && itemContent.html && (
              <div className="hero-text-slider-item-content" dangerouslySetInnerHTML={{ __html: itemContent.html }} />
            )}
            {itemSmallText && itemSmallText.text && (
              <div className="hero-text-slider-item-small">{itemSmallText.text}</div>
            )}
          </div>
        );
      })}
    </Carousel>
  );
};

const HeroImageSlider = ({ items, onSlideChanged }) => {
  if (!items || items.length < 1) return null;
  return (
    <Carousel
      onSlideChanged={onSlideChanged}
      containerClassName="hero-image-slider"
      infinite
      swipeDisabled
      dotsDisabled
      disableSlider={items.length < 2}
    >
      {items.map(item => {
        const { image, photography, illustration } = item;

        const imageToUse = () => {
          if (photography && photography.url) return photography;
          if (illustration && illustration.url) return illustration;
          return image;
        };

        const classNameToUse = () => {
          if (illustration && illustration.url) return 'with-illustration';
          if (photography && photography.url) return 'hero-image-fill';
          return '';
        };

        const headerImage = imageToUse();
        const headerImageClassName = classNameToUse();

        return <Image key={headerImage.url} className={headerImageClassName} image={headerImage} />;
      })}
    </Carousel>
  );
};

export default Hero;
