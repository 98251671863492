import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, ListingGrid, SectionHeader } from 'components';
import './styles.scss';

/* eslint prefer-spread: 0 */

const getSubCategoryQuery = category => {
  if (category === 'Office Milk') return 'office-milk';
  if (category === 'Office Fruit') return 'office-fruit';
  return '';
};

const SupplierListings = ({ data, locationData }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [listings, setListings] = useState(Array.apply(null, { length: 6 }).map((_, index) => index));
  const { suburb, state, category } = locationData;

  const BASE_URL = 'https://app.yordar.com.au';
  const API_ENDPOINT = 'suppliers.json';
  const LIMIT = 6;
  const CATEGORY_GROUP = category === 'Office Catering' ? 'office-catering' : 'office-snacks';
  const SUB_CATEGORY_QUERY = getSubCategoryQuery(category);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const apiUrl = `${BASE_URL}/${API_ENDPOINT}?limit=${LIMIT}&searchable=true&suburb=${suburb}&state=${state}&category_group=${CATEGORY_GROUP}${
        SUB_CATEGORY_QUERY ? `&category[]=${SUB_CATEGORY_QUERY}` : ''
      }`;
      try {
        const result = await axios(apiUrl);
        setListings(result.data);
      } catch (e) {
        console.error('There was an error accessing the following URL', { apiUrl, error });
        setError(e);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const { primary } = data;
  const { title, tagline } = primary;
  const taglineText = tagline && tagline.text;
  const titleText = title && title.text;

  return (
    <section className="supplier-listings">
      <div className="wrapper">
        <SectionHeader tagline={taglineText} TaglineTag="h2" title={titleText} TitleTag="span" />
        <ListingGrid listings={listings} loading={loading} error={error} />
        <div className="supplier-listings-actions">
          <Link
            className="button"
            to={`https://order.yordar.com.au/search/${CATEGORY_GROUP}/${state}/${suburb}${
              SUB_CATEGORY_QUERY ? `?filters=${SUB_CATEGORY_QUERY}` : ''
            }`}
            isLocationPage
          >
            View All {suburb} {category === 'Office Catering' ? 'Caterers' : 'Suppliers'}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SupplierListings;
