import React from 'react';
import { UserConsumer } from '../context/user';
import { Link } from './Link';
import CartClear from './CartClear';
import './HeaderActions.scss';

export const HeaderActions = ({ location }) => (
  <UserConsumer>
    {({ user, cart }) => {
      const loggedIn = user;
      const hasCartItems = cart && cart.count > 0;

      const getAccountLink = () => {
        if (!user) return null;
        if (user.type === 'admin') return 'admin';
        if (user.type === 'supplier') return 's_profile';
        return 'c_profile';
      };

      const actionLinks = loggedIn
        ? [{ to: `https://app.yordar.com.au/${getAccountLink()}`, text: `Hi ${user.first_name}` }]
        : [
          { to: 'https://app.yordar.com.au/login', text: 'Login' },
          { to: 'https://app.yordar.com.au/register', text: 'Register' },
        ];

      return (
        <div className="header-actions">
          <a href={`${process.env.GATSBY_NEXT_APP_URL}/quotes`} className="button black outline small quote">
            Get A Quote
          </a>
          {actionLinks.map(link => (
            <Link className="header-actions-link" key={link.text} to={link.to}>
              {link.text}
            </Link>
          ))}
          <Link
            className="header-actions-link icon icon-shopcart-dark"
            to={`${process.env.GATSBY_NEXT_APP_URL}/checkout`}
          >
            {hasCartItems && <span className="badge">{cart.count}</span>}
          </Link>
          <CartClear show={hasCartItems} />
        </div>
      );
    }}
  </UserConsumer>
);
