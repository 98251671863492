import React from 'react';

const getQuoteCateogryFromCta = ctaUi => {
  const categories = {
    'Quote Button Catering': 'catering',
    'Quote Button Snacks': 'snacks',
    'Quote Button Event': 'event',
  };

  return categories[ctaUi];
};

export const QuoteButton = ({ ctaUi }) => (
  <a href={`${process.env.GATSBY_NEXT_APP_URL}/quotes/${getQuoteCateogryFromCta(ctaUi)}`} className="button primary">
    Get A Quote
  </a>
);
