const acceptedFilters = [
  'breaky',
  'morning-tea',
  'afternoon-tea',
  'finger-food',
  'seminars',
  'buffets',
  'office-pizza',
  'burgers',
  'healthy-catering',
  'office-milk',
  'office-fruit',
  'coffee-supplies',
  'office-bread',
  'office-snacks-pantry',
  'office-drinks',
  'office-alcohol',
  'kitchenware',
  'cleaning-supplies',
];

export default acceptedFilters;
